<template>
	<main id="liste">
		<HeaderTab :title="$t('model.personnalisation_model_courrier_client_area')" />
		
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<EditModel :modelable_id="modelableId" :modelable_type="modelableType" :model_id="modelId" />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">

	export default {
		name: "EditModelClientArea",
		computed: {
			modelableId () {
				return this.$route.params.modelable_id
			},
			modelableType () {
				return this.$route.params.modelable_type
			},
			modelId () {
				return this.$route.params.model_id
			}
		},
		components: {
			HeaderTab : () => import('@/components/HeaderTab'),
			EditModel : () => import('@/components/Model/Edit')
		}
	}

</script>
